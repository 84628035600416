import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode';
import CustomGoogleButton from './CustomGoogleButton'; // Import the custom button component
import './AuthSocial.css';

export default function AuthSocial() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  
  const responseGoogleSuccess = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    setIsUserLoggedIn(true);
  };

  const responseGoogleFailure = (error) => {
    console.error('Google login failed', error);
  };

  return (
    <div className="AuthSocial">
      {!isUserLoggedIn && (
        <GoogleLogin
          onSuccess={responseGoogleSuccess}
          onError={responseGoogleFailure}
          useOneTap={false}
          locale='en-US'
          width={400}
        
       
          
      
        />
      )}
    </div>
  );
}
