import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import SplitType from 'split-type'; // To split text into words or letters for animation
import config from './config/index.json';

const MainHero = () => {
  const { mainHero } = config;
  const subtitleRef = useRef(null);
  const subtitle2Ref = useRef(null);
  const descriptionRef = useRef(null); // New ref for description
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Splitting the text into words for a staggered effect
    const subtitleText = new SplitType(subtitleRef.current, { types: 'words' });
    const subtitle2Text = new SplitType(subtitle2Ref.current, { types: 'words' });
    const descriptionText = new SplitType(descriptionRef.current, { types: 'chars' }); // Split description into characters

    // GSAP timeline for attractive animation
    const tl = gsap.timeline();

    // Animate each word in the subtitles with rotation and skew
    tl.from(subtitleText.words, {
      opacity: 0,
      y: 50,
      scale: 0.5,
      rotationX: 90, // Rotate in 3D
      skewX: 10, // Add a skew for a more dynamic feel
      duration: 1.2,
      stagger: 0.15,
      ease: 'elastic.out(1, 0.75)', // More dramatic easing
    });

    tl.from(subtitle2Text.words, {
      opacity: 0,
      y: 50,
      scale: 0.5,
      rotationZ: 45,
      duration: 1.2,
      stagger: 0.15,
      ease: 'bounce.out',
    }, '-=0.8'); // Overlap the second animation slightly

    // Animate the description text in a wave effect
    tl.from(descriptionText.chars, {
      opacity: 0,
      y: 20, // Slide in from below
      duration: 0.5,
      stagger: {
        amount: 0.5, // Total duration of stagger
        from: "start", // Start from the first letter
        ease: "power2.out", // Ease for a smoother transition
      },
    }, "-=0.5"); // Start this animation slightly earlier

    // Add background animation for the main section
    // gsap.to('main', {
    //   background: "linear-gradient(90deg, rgba(33, 66, 104, 1) 0%, rgba(33, 66, 104, 0.8) 100%)",
    //   duration: 3,
    //   ease: "none",
    // });

    // Looping subtle pulse effect on subtitleRef
    gsap.to(subtitleRef.current, { scale: 1.02, repeat: -1, yoyo: true, duration: 1.5, ease: 'sine.inOut' });

  }, []);

  return (
    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 bg-none">
      <div className="sm:text-center lg:text-left">
        <h1 className="text-7xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-5xl">
          <span className="block xl:inline " style={{ color: '#c6b250' }}>
            {mainHero.title}
          </span>
          <br />
          <span 
            ref={subtitleRef} 
            className="block xl:inline cursor-pointer" 
            onMouseEnter={() => gsap.to(subtitleRef.current, { scale: 1.05, color: '#c6b250', duration: 0.3 })} 
            onMouseLeave={() => gsap.to(subtitleRef.current, { scale: 1, color: '#000', duration: 0.3 })}
          >
            {mainHero.subtitle}
          </span>
          <br />
          <span 
            ref={subtitle2Ref} 
            className="block xl:inline cursor-pointer" 
            onMouseEnter={() => gsap.to(subtitle2Ref.current, { scale: 1.05, color: '#c6b250', duration: 0.3 })} 
            onMouseLeave={() => gsap.to(subtitle2Ref.current, { scale: 1, color: '#000', duration: 0.3 })}
          >
            {mainHero.subtitle2}
          </span>
        </h1>
        <br />

        <p
          ref={descriptionRef} // Ref added here
          className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
          dangerouslySetInnerHTML={{ __html: mainHero.description }}
        />

        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          {!token && (
            <div className="rounded-md shadow">
              <a
                style={{ backgroundColor: 'rgb(33, 66, 104)' }}
                href={mainHero.primaryAction.href}
                className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-background hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
              >
                {mainHero.primaryAction.text}
              </a>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default MainHero;
