
import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography,
  Box, Skeleton, TableFooter, TablePagination, TextField, InputAdornment, Button
} from '@mui/material';
import { Search } from '@mui/icons-material';
import './ForexNews.css';
import DashboardNavbar from '../../layouts/dashboard/DashboardNavbar';
import NavbarComponent from '../../components/NewNav';
import UserDashboardNavbar from '../../layouts/dashboard copy/DashboardNavbar';
import About from '../../components/About';

const ForexNews = () => {
  const currentPath = window.location.pathname;
  const [newsEvents, setNewsEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [impactFilter, setImpactFilter] = useState('');

  const fetchEconomicData = async () => {
    try {
      const today = new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      const response = await fetch(`https://traderz.fund:5000/api/economic-calendar?date_time=${encodeURIComponent(today)}`);
      const data = await response.json();
  
      if (data.length === 0) {
        // If no events are returned, fetch data using fallback date '201'
        const fallbackResponse = await fetch(`https://traderz.fund:5000/api/economic-calendar?date_time=Oct%201`);
        const fallbackData = await fallbackResponse.json();
        setNewsEvents(fallbackData);
        setFilteredEvents(fallbackData);
      } else {
        setNewsEvents(data);
        setFilteredEvents(data);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching economic data:', error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchEconomicData();
    setTimeout(fetchEconomicData, 600000); // Auto-fetch every 10 minutes
  }, []);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchValue(searchTerm);
    filterData(searchTerm, impactFilter);
  };

  const handleImpactFilter = (impact) => {
    setImpactFilter(impact);
    filterData(searchValue, impact);
  };

  const filterData = (searchTerm, impact) => {
    const filtered = newsEvents.filter(event => {
      const matchesSearch = event.country.toLowerCase().includes(searchTerm);
      const matchesImpact = impact ? event.impact === impact : true;
      return matchesSearch && matchesImpact;
    });
    setFilteredEvents(filtered);
  };

  const formatDateTime = (dateTime) => {
    const [date, time] = dateTime.split(', ');
    return { date, time };
  };

  const getFlagUrl = (country) => {
    const flags = {
      'Australia': 'https://flagsapi.com/AU/flat/64.png',
      'Finland': 'https://flagsapi.com/FI/flat/64.png',
      'Spain': 'https://flagsapi.com/ES/flat/64.png',
      'China': 'https://flagsapi.com/CN/flat/64.png',
      'Ireland': 'https://flagsapi.com/IE/flat/64.png',
      'Portugal': 'https://flagsapi.com/PT/flat/64.png',
      'Canada': 'https://flagsapi.com/CA/flat/64.png',
      'New Zealand': 'https://flagsapi.com/NZ/flat/64.png',
      'Euro Area': 'https://flagsapi.com/ES/flat/64.png',
      'United Kingdom': 'https://flagsapi.com/GB/flat/64.png',
      'France': 'https://flagsapi.com/FR/flat/64.png',
      'Italy': 'https://flagsapi.com/IT/flat/64.png',
      'United States': 'https://flagsapi.com/US/flat/64.png',
      'Japan': 'https://flagsapi.com/JP/flat/64.png',
      'India': 'https://flagsapi.com/IN/flat/64.png',
      'South Africa': 'https://flagsapi.com/ZA/flat/64.png',
      'Switzerland': 'https://flagsapi.com/CH/flat/64.png',
      'Netherlands': 'https://flagsapi.com/NL/flat/64.png',
      'Germany': 'https://flagsapi.com/DE/flat/64.png',
      'Sweden': 'https://flagsapi.com/SE/flat/64.png',
      'Belgium': 'https://flagsapi.com/BE/flat/64.png',
      'Norway': 'https://flagsapi.com/NO/flat/64.png',
      'Denmark': 'https://flagsapi.com/DK/flat/64.png',
      'Austria': 'https://flagsapi.com/AT/flat/64.png',
      'Czech Republic': 'https://flagsapi.com/CZ/flat/64.png',
      'Slovakia': 'https://flagsapi.com/SK/flat/64.png',
      'Hungary': 'https://flagsapi.com/HU/flat/64.png',
      'Poland': 'https://flagsapi.com/PL/flat/64.png',
      'Romania': 'https://flagsapi.com/RO/flat/64.png',
      'Greece': 'https://flagsapi.com/GR/flat/64.png',
      'Croatia': 'https://flagsapi.com/HR/flat/64.png',
      'Bulgaria': 'https://flagsapi.com/BG/flat/64.png',
      'Serbia': 'https://flagsapi.com/RS/flat/64.png',
      'Turkey': 'https://flagsapi.com/TR/flat/64.png',
      'Slovenia': 'https://flagsapi.com/SI/flat/64.png',
      'Lithuania': 'https://flagsapi.com/LT/flat/64.png',
      'Latvia': 'https://flagsapi.com/LV/flat/64.png',
      'Estonia': 'https://flagsapi.com/EE/flat/64.png',
      'Bosnia and Herzegovina': 'https://flagsapi.com/BA/flat/64.png',
      'Albania': 'https://flagsapi.com/AL/flat/64.png',
      'Greece': 'https://flagsapi.com/GR/flat/64.png',
      'Cyprus': 'https://flagsapi.com/CY/flat/64.png',
      'Malta': 'https://flagsapi.com/MT/flat/64.png',
      'Iceland': 'https://flagsapi.com/IS/flat/64.png',
      'Andorra': 'https://flagsapi.com/AD/flat/64.png',
      'Monaco': 'https://flagsapi.com/MC/flat/64.png',
      'Liechtenstein': 'https://flagsapi.com/LI/flat/64.png',
      'San Marino': 'https://flagsapi.com/SM/flat/64.png',
      'Vatican City': 'https://flagsapi.com/VA/flat/64.png',
      'Malta': 'https://flagsapi.com/MT/flat/64.png',
      'Monaco': 'https://flagsapi.com/MC/flat/64.png',
      'Liechtenstein': 'https://flagsapi.com/LI/flat/64.png',
      'San Marino': 'https://flagsapi.com/SM/flat/64.png',
      'Vatican City': 'https://flagsapi.com/VA/flat/64.png',
      'Congo': 'https://flagsapi.com/CG/flat/64.png',
      'Angola': 'https://flagsapi.com/AO/flat/64.png',
      'Botswana': 'https://flagsapi.com/BW/flat/64.png',
      'Namibia': 'https://flagsapi.com/NA/flat/64.png',
      'Zambia': 'https://flagsapi.com/ZM/flat/64.png',
      'Zimbabwe': 'https://flagsapi.com/ZW/flat/64.png',  
      'Cote d\'Ivoire': 'https://flagsapi.com/CI/flat/64.png',
      'Mali': 'https://flagsapi.com/ML/flat/64.png',
      'Senegal': 'https://flagsapi.com/SN/flat/64.png',
      'Guinea': 'https://flagsapi.com/GN/flat/64.png',
      'Rwanda': 'https://flagsapi.com/RW/flat/64.png',
      'Benin': 'https://flagsapi.com/BJ/flat/64.png',
      'Burkina Faso': 'https://flagsapi.com/BF/flat/64.png',
      'Togo': 'https://flagsapi.com/TO/flat/64.png',
      'Niger': 'https://flagsapi.com/NE/flat/64.png',
      'Nigeria': 'https://flagsapi.com/NG/flat/64.png',
      'Chad': 'https://flagsapi.com/TD/flat/64.png',
      'Mauritania': 'https://flagsapi.com/MR/flat/64.png',
      'Mozambique': 'https://flagsapi.com/MZ/flat/64.png',  
      'Zambia': 'https://flagsapi.com/ZM/flat/64.png',
      'Zimbabwe': 'https://flagsapi.com/ZW/flat/64.png',
      'Cote d\'Ivoire': 'https://flagsapi.com/CI/flat/64.png',
      'Mali': 'https://flagsapi.com/ML/flat/64.png',
      'Senegal': 'https://flagsapi.com/SN/flat/64.png',
      'Guinea': 'https://flagsapi.com/GN/flat/64.png',
      'Rwanda': 'https://flagsapi.com/RW/flat/64.png',  
      'Benin': 'https://flagsapi.com/BJ/flat/64.png',
      'Burkina Faso': 'https://flagsapi.com/BF/flat/64.png',
      'Togo': 'https://flagsapi.com/TO/flat/64.png',
      'Niger': 'https://flagsapi.com/NE/flat/64.png',
      'Nigeria': 'https://flagsapi.com/NG/flat/64.png',
      'Chad': 'https://flagsapi.com/TD/flat/64.png',
      'Mauritania': 'https://flagsapi.com/MR/flat/64.png',
      'Mozambique': 'https://flagsapi.com/MZ/flat/64.png',  
      'Zambia': 'https://flagsapi.com/ZM/flat/64.png',
      'Zimbabwe': 'https://flagsapi.com/ZW/flat/64.png',
      'Cote d\'Ivoire': 'https://flagsapi.com/CI/flat/64.png',
      'Mali': 'https://flagsapi.com/ML/flat/64.png',
      'Senegal': 'https://flagsapi.com/SN/flat/64.png',
      'Guinea': 'https://flagsapi.com/GN/flat/64.png',
      'Rwanda': 'https://flagsapi.com/RW/flat/64.png',
      'Benin': 'https://flagsapi.com/BJ/flat/64.png',
      'Burkina Faso': 'https://flagsapi.com/BF/flat/64.png',
      'Togo': 'https://flagsapi.com/TO/flat/64.png',
      'Niger': 'https://flagsapi.com/NE/flat/64.png',
      'Nigeria': 'https://flagsapi.com/NG/flat/64.png',
      'Chad': 'https://flagsapi.com/TD/flat/64.png',
      'Mauritania': 'https://flagsapi.com/MR/flat/64.png',
      'Mozambique': 'https://flagsapi.com/MZ/flat/64.png',  
      'Zambia': 'https://flagsapi.com/ZM/flat/64.png',
      'Zimbabwe': 'https://flagsapi.com/ZW/flat/64.png',
      'Cote d\'Ivoire': 'https://flagsapi.com/CI/flat/64.png',
      'Mali': 'https://flagsapi.com/ML/flat/64.png',
      'Senegal': 'https://flagsapi.com/SN/flat/64.png',
      'Guinea': 'https://flagsapi.com/GN/flat/64.png',
      'Rwanda': 'https://flagsapi.com/RW/flat/64.png',
      'Benin': 'https://flagsapi.com/BJ/flat/64.png',
      'Burkina Faso': 'https://flagsapi.com/BF/flat/64.png',
      'Togo': 'https://flagsapi.com/TO/flat/64.png',
      'Niger': 'https://flagsapi.com/NE/flat/64.png',
      'Nigeria': 'https://flagsapi.com/NG/flat/64.png',
      'Chad': 'https://flagsapi.com/TD/flat/64.png',
      'Mauritania': 'https://flagsapi.com/MR/flat/64.png',
      'Mozambique': 'https://flagsapi.com/MZ/flat/64.png',  
      'Zambia': 'https://flagsapi.com/ZM/flat/64.png',
      'Zimbabwe': 'https://flagsapi.com/ZW/flat/64.png',
      'Cote d\'Ivoire': 'https://flagsapi.com/CI/flat/64.png',
      'Mali': 'https://flagsapi.com/ML/flat/64.png',
      'Senegal': 'https://flagsapi.com/SN/flat/64.png',
      'Guinea': 'https://flagsapi.com/GN/flat/64.png',
      'Rwanda': 'https://flagsapi.com/RW/flat/64.png',  
      'Benin': 'https://flagsapi.com/BJ/flat/64.png',
      'Burkina Faso': 'https://flagsapi.com/BF/flat/64.png',
      'Togo': 'https://flagsapi.com/TO/flat/64.png',
      'Niger': 'https://flagsapi.com/NE/flat/64.png',
      'Nigeria': 'https://flagsapi.com/NG/flat/64.png',
      'Chad': 'https://flagsapi.com/TD/flat/64.png',
      'Mauritania': 'https://flagsapi.com/MR/flat/64.png',
      'Mozambique': 'https://flagsapi.com/MZ/flat/64.png',  
      'Zambia': 'https://flagsapi.com/ZM/flat/64.png',
      'Zimbabwe': 'https://flagsapi.com/ZW/flat/64.png',
      'Cote d\'Ivoire': 'https://flagsapi.com/CI/flat/64.png',
      'Mali': 'https://flagsapi.com/ML/flat/64.png',
      'Senegal': 'https://flagsapi.com/SN/flat/64.png',
      'Guinea': 'https://flagsapi.com/GN/flat/64.png',
      'Rwanda': 'https://flagsapi.com/RW/flat/64.png',
      'Benin': 'https://flagsapi.com/BJ/flat/64.png',
      'Burkina Faso': 'https://flagsapi.com/BF/flat/64.png',
      'Togo': 'https://flagsapi.com/TO/flat/64.png',
      'Niger': 'https://flagsapi.com/NE/flat/64.png',
      'Nigeria': 'https://flagsapi.com/NG/flat/64.png',
      'Chad': 'https://flagsapi.com/TD/flat/64.png',
      'Mauritania': 'https://flagsapi.com/MR/flat/64.png',
      'Mozambique': 'https://flagsapi.com/MZ/flat/64.png',  
      'Zambia': 'https://flagsapi.com/ZM/flat/64.png', 
      'Montenegro ': 'https://flagsapi.com/ME/flat/64.png',
      'Zimbabwe': 'https://flagsapi.com/ZW/flat/64.png',
      'Cote d\'Ivoire': 'https://flagsapi.com/CI/flat/64.png',  
      'Mali': 'https://flagsapi.com/ML/flat/64.png',
    };

    return flags[country] || '';
  };
  const isUserLoggedIn = localStorage.getItem('name') && localStorage.getItem('email');
  const isUserAdmin = localStorage.getItem('role');

  const getColorForValue = (value) => {
    if (!value) return null;
    const numValue = parseFloat(value);
    return numValue < 0 ? 'red' : 'green';
  };

  const getImpactBackgroundColor = (impact) => {
    switch (impact) {
      case 'Low':
        return 'green';
      case 'Medium':
        return 'yellow';
      case 'High':
        return 'red';
      default:
        return '';
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {!isUserLoggedIn && currentPath === '/forexbots/forex-news' && <NavbarComponent />}
  
      {isUserLoggedIn && isUserAdmin === 'admin' && currentPath === '/forexbots/forex-news' && (
        <DashboardNavbar />
      )}
  
      {isUserLoggedIn && isUserAdmin !== 'admin' && currentPath === '/forexbots/forex-news' && (
        <UserDashboardNavbar />
      )}
  
      <Box className="forex-news-container" id="fec">
        <Typography variant="h4" className="forex-news-title mt-5" gutterBottom>
          Forex Economic Calendar
        </Typography>
  
        {/* Search and Filter */}
        <div className='w-75 justify-start'>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <TextField
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Search by Country"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ mr: 2 }}
          />
  
  <TextField
                      label=""
                      select
                      className='w-25'
                      value={impactFilter}
                      onChange={(e) => handleImpactFilter(e.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">All</option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </TextField>
        </Box>
  
        </div>
       
        <Box className="table-wrapper">
          <TableContainer component={Paper} className="forex-news-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Event</TableCell>
                  <TableCell>Impact</TableCell>
                  <TableCell>Previous</TableCell>
                  <TableCell>Consensus</TableCell>
                  <TableCell>Actual</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  [...Array(5)].map((_, index) => (
                    <TableRow key={index}>
                      <TableCell><Skeleton width="80%" /></TableCell>
                      <TableCell><Skeleton width="60%" /></TableCell>
                      <TableCell><Skeleton width="50%" /></TableCell>
                      <TableCell><Skeleton width="90%" /></TableCell>
                      <TableCell><Skeleton width="40%" /></TableCell>
                      <TableCell><Skeleton width="50%" /></TableCell>
                      <TableCell><Skeleton width="50%" /></TableCell>
                      <TableCell><Skeleton width="50%" /></TableCell>
                    </TableRow>
                  ))
                ) : (
                  filteredEvents
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((event, index) => {
                      const { date, time } = formatDateTime(event.date_time);
                      const flagUrl = getFlagUrl(event.country);
  
                      return (
                        <TableRow key={index}>
                          <TableCell>{date}</TableCell>
                          <TableCell>{time}</TableCell>
                          <TableCell>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={flagUrl} alt={`flag of ${event.country}`} style={{ width: '24px', marginRight: '8px' }} />
                              <span>{event.country} ({event.currency})</span>
                            </span>
                          </TableCell>
                          <TableCell>{event.event}</TableCell>
                          <TableCell
                            style={{
                              backgroundColor: getImpactBackgroundColor(event.impact),
                              color: event.impact === 'Medium' ? 'black' : 'white',
                            }}
                          >
                            {event.impact}
                          </TableCell>
                          <TableCell>{event.previous || '-'}</TableCell>
                          <TableCell>{event.consensus || '-'}</TableCell>
                          <TableCell style={{ color: getColorForValue(event.actual) }}>
                            {event.actual || '-'}
                          </TableCell>
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={filteredEvents.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
  
      {currentPath !== '/forexbots' && <About />}
    </>
  );
  
  
};

export default ForexNews;
