import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import axios from "axios";
import "./NewNave.css";
import logo from "../Home/img/mainlogo.png"; // Adjust path if necessary
import en from "../Home/img/Flag_of_the_United_States.png"; // Adjust path if necessary
import ar from "../Home/img/Flag_of_Saudi_Arabia.svg.png"; // Adjust path if necessary
import LanguagePopover from "../layouts/dashboard/LanguagePopover";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
export default function NavbarComponent({onLanguageChange}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [subscriptionCode, setSubscriptionCode] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadUrl, setUploadUrl] = useState("");
  const [currentPath, setCurrentPath] = useState(""); // State to store the current path

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setOrderData(null); // Clear order data when closing
    setFile(null); // Clear file when closing
    setUploadUrl(""); // Clear upload URL when closing
  };

  const handleSubscriptionCodeChange = (event) => {
    setSubscriptionCode(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleCheckOrder = async () => {
    try {
      const response = await axios.get(`https://traderz.fund:3060/users/getOrder/${subscriptionCode}`);
      const data = response.data;
      setOrderData(data[0] || {}); // Assuming the response is an array and we use the first item
    } catch (error) {
      console.error("Error fetching order data:", error);
      // Handle error accordingly
    }
  };

  const handleUploadAttachment = async () => {
    if (!file || !subscriptionCode) {
      console.error("No file selected or subscription code not provided.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append('img', file);

      const response = await axios.put(
        `https://traderz.fund:3060/users/getOrder/${subscriptionCode}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Handle success response
      alert("Attachment uploaded successfully!");
      window.location.reload();
      setFile(null); // Clear file after successful upload

    } catch (error) {
      console.error("Error uploading attachment:", error);
      // Handle error accordingly
      alert("Failed to upload attachment. Please try again.");
    }
  };

  // useEffect to update the current path state
  useEffect(() => {
    setCurrentPath(window.location.pathname);

  }, []);
  
const selectedLanguage=window.localStorage.getItem('selectedLanguage');

  return (
    <div>
      { currentPath === '/' ? (
  <AppBar className="appbar" position="static" color="default" style={{ backgroundColor: "white", height: "120px" }}>
    <Toolbar>
      <Typography variant="h6" component={Link} to="/" className="logo" sx={{ flexGrow: 1 }}>
        <img src={logo} alt="Logo" className="logo-img"  />
      </Typography>
      <div className="desktop-menu">
        <Button variant="contained" style={{ backgroundColor: 'rgb(255, 193, 7)', color:'#234264' }} className="order-button me-5 ">
          {selectedLanguage === 'ar' ? 'الصفحة الرئيسية' : 'Home'}
        </Button>
        <ScrollLink to="about" smooth={true} duration={500} className="nav-link">
          <Button color="inherit" className="nav-link ">
            {selectedLanguage === 'ar' ? 'حول' : 'About'}
          </Button>
        </ScrollLink>
        <ScrollLink to="services" smooth={true} duration={500} className="nav-link ">
          <Button color="inherit">
            {selectedLanguage === 'ar' ? 'خدمات' : 'Services'}
          </Button>
        </ScrollLink>
        <ScrollLink to="whyus" smooth={true} duration={500} className="nav-link ">
          <Button color="inherit" className="nav-link ">
            {selectedLanguage === 'ar' ? 'لماذا نحن' : 'Why Us'}
          </Button>
        </ScrollLink>
        <ScrollLink to="contactus" smooth={true} duration={500} className="nav-link ">
          <LanguagePopover onLanguageChange={onLanguageChange} />
        </ScrollLink>
      </div>
      <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen} className="mobile-menu-button">
        <MenuIcon />
      </IconButton>
      <Menu
      
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <ScrollLink to="Home" smooth={true} duration={500} className="nav-link">
        <MenuItem onClick={handleMenuClose} component={Link} to="/">
          {selectedLanguage === 'ar' ? 'الصفحة الرئيسية' : 'Home'}
        </MenuItem>
      </ScrollLink>
      <ScrollLink to="about" smooth={true} duration={500} className="nav-link">
        <MenuItem onClick={handleMenuClose}>
          {selectedLanguage === 'ar' ? 'من نحن' : 'about'}
        </MenuItem>
      </ScrollLink>
      <ScrollLink to="services" smooth={true} duration={500} className="nav-link">
        <MenuItem onClick={handleMenuClose} >
          {selectedLanguage === 'ar' ? ' خدماتنا' : 'services '}
        </MenuItem>
      </ScrollLink>
      <ScrollLink to="whyus" smooth={true} duration={500} className="nav-link">
      <MenuItem onClick={handleMenuClose} >
        {selectedLanguage === 'ar' ? ' لماذا نحن' : 'whyus '}
      </MenuItem>
    </ScrollLink>
    <ScrollLink to="contactus" smooth={true} duration={500} className="nav-link text-white">
    <LanguagePopover onLanguageChange={onLanguageChange} />
  </ScrollLink>
    
    </Menu>
    </Toolbar>
  </AppBar>
) : (
  <AppBar position="static" color="default" style={{ backgroundColor: "rgb(34, 66, 102)", height: "120px" }}>
    <Toolbar>
      <Typography variant="h6" component={Link} to="/" className="logo" sx={{ flexGrow: 1 }}>
        <img src={logo} alt="Logo" className="logo-img" />
      </Typography>
      <div className="desktop-menu">
        <Button variant="contained" style={{ backgroundColor: '#dbc657 ', color:'#234264' }} onClick={handleDialogOpen} className="order-button me-5">
          {selectedLanguage === 'ar' ? 'Check My Order': 'Check My Order'}
        </Button>
        <ScrollLink to="products" smooth={true} duration={500} className="nav-link">
          <Button color="inherit" className="nav-link text-white">
            {selectedLanguage === 'ar' ?  'Trading Bots' : 'Trading Bots'}
          </Button>
        </ScrollLink>
        <ScrollLink to="fec" smooth={true} duration={500} className="nav-link">
          <Button color="inherit" className="nav-link text-white">
            {selectedLanguage === 'ar' ?  'FEC' : 'FEC'}
          </Button>
        </ScrollLink>
        <ScrollLink to="Strategy" smooth={true} duration={500} className="nav-link text-white">
          <Button color="inherit">
            {selectedLanguage === 'ar' ?'Strategy' : 'Strategy'}
          </Button>
        </ScrollLink>
        <ScrollLink to="contactus" smooth={true} duration={500} className="nav-link text-white">
          <Button style={{color:'white'}} color="inherit" className="nav-link">
            {selectedLanguage === 'ar' ? 'Contact Us' : 'Contact Us'}
          </Button>
        </ScrollLink>
        <Button variant="outlined" component={Link} to="/login" className="login-button">
          {selectedLanguage === 'ar' ? 'Login' : 'Login'}
        </Button>
        <Button variant="contained" color="primary" component={Link} to="/register" className="join-button">
          {selectedLanguage === 'ar' ? 'Join' : 'Join'}
        </Button>
      </div>
      <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen} className="mobile-menu-button">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <ScrollLink to="Pricing" smooth={true} duration={500} className="nav-link">
          <MenuItem onClick={handleMenuClose} component={Link} to="/">
            {selectedLanguage === 'ar' ? 'التسعير' : 'Pricing'}
          </MenuItem>
        </ScrollLink>
        <ScrollLink to="product" smooth={true} duration={500} className="nav-link">
          <MenuItem onClick={handleMenuClose}>
            {selectedLanguage === 'ar' ? 'المنتجات' : 'Products'}
          </MenuItem>
        </ScrollLink>
        <ScrollLink to="contactus" smooth={true} duration={500} className="nav-link">
          <MenuItem onClick={handleMenuClose} component={Link} to="/contact">
            {selectedLanguage === 'ar' ? 'اتصل بنا' : 'Contact Us'}
          </MenuItem>
        </ScrollLink>
        <MenuItem onClick={handleMenuClose} component={Link} to="/login">
          {selectedLanguage === 'ar' ? 'تسجيل الدخول' : 'Login'}
        </MenuItem>
        <MenuItem onClick={handleMenuClose} component={Link} to="/register">
          {selectedLanguage === 'ar' ? 'انضم' : 'Join'}
        </MenuItem>
        <MenuItem onClick={handleDialogOpen}>
          {selectedLanguage === 'ar' ? 'تحقق من طلبي' : 'Check My Order'}
        </MenuItem>
      </Menu>
    </Toolbar>
  </AppBar>
)}

        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Announcement</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="subscription-code"
              label="Subscription Code"
              type="text"
              fullWidth
              variant="standard"
              value={subscriptionCode}
              onChange={handleSubscriptionCodeChange}
            />
            {orderData && (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Field</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderData.paymentMethod && (
                      <TableRow>
                        <TableCell>Payment Method</TableCell>
                        <TableCell>{orderData.paymentMethod}</TableCell>
                      </TableRow>
                    )}
                    {orderData.bankName && (
                      <TableRow>
                        <TableCell>Bank Name</TableCell>
                        <TableCell>{orderData.bankName}</TableCell>
                      </TableRow>
                    )}
                    {orderData.bankIban && (
                      <TableRow>
                        <TableCell>Bank IBAN</TableCell>
                        <TableCell>{orderData.bankIban}</TableCell>
                      </TableRow>
                    )}
                    {orderData.bankCountry && (
                      <TableRow>
                        <TableCell>Bank Country</TableCell>
                        <TableCell>{orderData.bankCountry}</TableCell>
                      </TableRow>
                    )}
                    {orderData.bankSwiftCode && (
                      <TableRow>
                        <TableCell>Bank Swift Code</TableCell>
                        <TableCell>{orderData.bankSwiftCode}</TableCell>
                      </TableRow>
                    )}
                    {orderData.paid_price !== undefined && (
                      <TableRow>
                        <TableCell>Paid Price</TableCell>
                        <TableCell>${orderData.paid_price}</TableCell>
                      </TableRow>
                    )}
                    {orderData.paymentStatus && (
                      <TableRow>
                        <TableCell>Payment Status</TableCell>
                        <TableCell>{orderData.paymentStatus}</TableCell>
                      </TableRow>
                    )}
                    {orderData.code && (
                      <TableRow>
                        <TableCell>Order Code</TableCell>
                        <TableCell>{orderData.code}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {file && (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Attachment Preview"
                    style={{ marginTop: '10px', width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                )}
              </TableContainer>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCheckOrder}>Check It!</Button>
            {orderData && (
              <>
                <Button
                  onClick={() => document.getElementById('file-input').click()}
                  disabled={!orderData}
                >
                  <InputAdornment position="end">
                    <AttachFileIcon />
                  </InputAdornment>
                  Select File
                </Button>
                <input
                  type="file"
                  id="file-input"
                  hidden
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <Button
                  onClick={handleUploadAttachment}
                  disabled={!file || !subscriptionCode}
                >
                  Submit
                </Button>
              </>
            )}
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  